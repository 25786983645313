import React, { ReactElement } from "react"

// import Bio from "../components/bio"
// import SEO from "../components/seo"

import { BasePage } from '../components/Base Page'
import { ContentCard } from "../components/Content Card/ContentCard";

import rugsPic1 from "../skrugs/Rugs/Rugs_1.png"

interface AboutPageState {
}

class AboutPage extends React.Component<{}, AboutPageState> {
  public static readonly displayName = "HomePage";

  constructor(p) {
    super(p);
    this.state = {};
  }

  public render() {
    var k = 0
    return (
      <BasePage key={(k++).toString()} 
        style={{maxWidth:"800px", marginLeft: "auto", marginRight: "auto"}}
        children={[
          // <div style={{
          //   // display: "flex",
          //   // flexDirection: "row",
          //   // flexWrap: "wrap",
          //   // justifyContent:" space-between",
          //   // alignItems: "stretch",
          // }}>
            <ContentCard key={(k++).toString()} children={[
              {type: "Heading3", content: "Who"},
              {type: "Text", content: <p key={(k++).toString()} className="Highlight"> <a key={(k++).toString()} href="https://bog.computer"> @b_o__g </a> </p>},
            // ]} />
            // <ContentCard  children={[
              {type: "Heading3", content: "What"},
              {type: "Text", content: <p key={(k++).toString()} className="Highlight"> <a key={(k++).toString()} href="https://en.wikipedia.org/wiki/Tufting">Hand Tufted Rugs</a></p>},
            // ]} />
            // <ContentCard children={[
              {type: "Heading3", content: "Where"},
              {type: "Text", content: <p key={(k++).toString()}><i key={(k++).toString()} className=""><a href="https://en.wikipedia.org/wiki/Ottawa">Ottawa, CA</a> </i> </p>},
            // ]} />
            // <ContentCard  children={[
              {type: "Heading3", content: "When"},
              {type: "Text", content: <p key={(k++).toString()}>Est. <i key={(k++).toString()} className="">2021</i></p>},
            // ]} />
            // <ContentCard  children={[
              {type: "Heading3", content: "Why"},
              {type: "Text", content: <p key={(k++).toString()}>An excuse to get off the computer and <i key={(k++).toString()} className="Highlight-Yellow"> make some cool, cute, spooky things.</i></p>},
              
              {type: "Heading3", content: "Note"},
              {type: "Text", content: <p key={(k++).toString()}>  I will <i key={(k++).toString()} className="Highlight-Red"> NOT</i> make other artist's work into rugs. You can make a request, but <i key={(k++).toString()} className="Highlight-Yellow">  all Skrug™ designs are my own</i>. Thank you!</p>},

              <ContentCard style={{marginTop: "40px"}} key={(k++).toString()} children={[
                <img style={{width: "70%", maxWidth:"400px", marginLeft:"auto", marginRight:"auto"}} src={rugsPic1} />,
              ]} />
            ]} />

          // </div>
        ]}
        currentSection="ABOUT"
      />
    )
  }
}

export default AboutPage;
